import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette, shadows, spacing }) => ({
    alert: {
      boxShadow: shadows[4],
      maxHeight: `calc(100vh - ${spacing(6)})`,

      '&.MuiAlert-filledSuccess': {
        color: palette.success.contrastText,
      },
    },

    alertAction: {
      flexShrink: 0,
      gap: spacing(0.5),
    },
    alertMessage: {
      whiteSpace: 'pre-wrap',
    },
    iconButton: {
      width: 32,
      height: 32,
      padding: 0,
    },
  }),
  { name: 'Message' },
);

function Message(props) {
  const { message, onClose, onExited, open } = props;
  const classes = useStyles(props);

  const { action, content, duration, type = 'success' } = message;

  const actionClick = function () {
    onClose();
    action.onClick?.();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={duration || 6000}
      onClose={onClose}
      open={open}
      slotProps={{ transition: { onExited } }}
    >
      <Alert
        action={
          <>
            {action && <Button {...action} color="inherit" onClick={actionClick} size="small" variant="outlined" />}
            <IconButton className={classes.iconButton} color="inherit" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </>
        }
        classes={{
          root: classes.alert,
          action: classes.alertAction,
          message: classes.alertMessage,
        }}
        icon={
          <FontAwesomeIcon
            icon={
              type === 'success' ? faCheckCircle
              : type === 'error' ?
                faExclamationCircle
              : type === 'info' ?
                faInfoCircle
              : faExclamationTriangle
            }
            size="sm"
          />
        }
        severity={type}
        variant="filled"
      >
        {content}
      </Alert>
    </Snackbar>
  );
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default Message;
