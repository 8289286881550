import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { useCurrentUser } from 'my-core/hooks';
import { useCurrentUserSubscription } from 'my-core/subscription-utils';
import { install, pageChange, TRACKING_DISABLED, userUpdate } from 'my-core/tag-manager';

export default function TagManager() {
  const user = useCurrentUser();
  const subscription = useCurrentUserSubscription();
  const location = useLocation();

  const initialMount = useRef(true);

  useEffect(() => {
    if (TRACKING_DISABLED || initialMount.current || user.guest) return;
    userUpdate(user, subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscription?.status, subscription?.is_premed_plus]);

  useEffect(() => {
    if (TRACKING_DISABLED) return;
    if (initialMount.current) {
      initialMount.current = false;
      install(user, subscription);
    } else {
      pageChange(user, subscription, location);
    }
  }, [location.pathname, location.search, subscription?.status, user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
