import { withStyles } from '@mui/styles';
import { Component } from 'react';
import { Navigate } from 'react-router';

// import BrowserNotification from 'my-core/browser-notification';
import PushPub from 'my-core/push-pub';
import { ringtone } from 'my-core/sound';

import LoadingIndicator from 'my-elements/LoadingIndicator';
import MuiAvatar from 'my-elements/MuiAvatar';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

class StudyRoomInvite extends Component {
  state = { redirect: null, status: 'ringing' };
  subscription = PushPub.getNotificationSubscription();

  componentDidMount() {
    // const { avatar, name } = this.props.notification.user;

    ringtone.play();
    // this.browserNotification = BrowserNotification.createNotification({
    //   title: 'Wizedemy - Study Invite',
    //   body: `${name} is inviting you to join his study room`,
    //   icon: avatar.thumb,
    //   onClick: this.acceptInvite
    // });
  }

  componentDidUpdate(prevProps) {
    const { actions, notification, redirect } = this.props;
    if (!prevProps.redirect && redirect) actions.removeNotification(notification.id);
  }

  componentWillUnmount() {
    ringtone.stop();
    this.browserNotification && this.browserNotification.close();
  }

  acceptInvite = () => {
    const { friendly_id, inviter_id } = this.props.notification;

    this.subscription.perform('accept_study_room_invite', { inviter_id, room_id: friendly_id });
    this.setState({ status: 'accepted' });
    ringtone.stop();
    this.browserNotification && this.browserNotification.close();
    this.subscription.on('received', this.handleRoomInitialized);
  };

  declineInvite = () => {
    const { actions, notification } = this.props;
    this.subscription.perform('decline_study_room_invite', { inviter_id: notification.inviter_id });
    actions.removeNotification(notification.id);
  };

  handleRoomInitialized = data => {
    if (data.action === 'STUDY_ROOM_INITIALIZED') {
      this.subscription.off('received', this.handleRoomInitialized);
      this.setState({ redirect: `/study-rooms/${data.friendly_id}` });
    }
  };

  render() {
    const { classes, notification } = this.props;
    const { redirect, status } = this.state;
    const accepted = status === 'accepted';

    if (redirect) return <Navigate to={redirect} />;

    return (
      <Dialog classes={{ paper: classes.root }} fullWidth open={true}>
        <MuiAvatar avatar={notification.user.avatar} classes={{ avatar: classes.avatar }} src="thumb" />
        <div className={classes.content}>
          <Typography color={accepted ? 'textSecondary' : 'textPrimary'} paragraph>
            You have been invited to join <b>{`${notification.user.name}`}</b> in a study room session. Would you like
            to join?
          </Typography>
          {accepted && <LoadingIndicator centered classes={{ centered: classes.loadingIndicator }} />}
          <div className={classes.buttons}>
            <Button color="secondary" onClick={this.declineInvite} size="large" variant="outlined">
              Ignore
            </Button>
            <Button
              className={classes.marginLeft2}
              disabled={accepted}
              fullWidth
              onClick={this.acceptInvite}
              size="large"
              variant="contained"
            >
              {`${accepted ? 'Joining' : 'Enter'} Study Room`}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(({ palette, shadows, spacing }) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing(3),
  },
  avatar: {
    marginRight: spacing(3),
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    boxShadow: shadows[2],
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
  },
  loadingIndicator: { marginBottom: spacing(2) },

  marginLeft2: { marginLeft: spacing(2) },
}))(StudyRoomInvite);
