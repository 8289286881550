export const WIZE_TOLL_FREE = '1-(888) 240-4066';
export const WIZE_SUBSCRIPTION_NAME = 'Wizeprep Plus';
export const PREMED_PRODUCT_NAME = 'Pre-Med Plus';

export const REFERRAL_DISCOUNT_AMOUNT = 15;
export const REFERRAL_REWARD_AMOUNT = 15;

const CA_PROVINCES = {
  AB: { name: 'Alberta' },
  BC: { name: 'British Columbia' },
  MB: { name: 'Manitoba' },
  NB: { name: 'New Brunswick' },
  NL: { name: 'Newfoundland and Labrador' },
  NT: { name: 'Northwest Territories' },
  NS: { name: 'Nova Scotia' },
  NU: { name: 'Nunavut' },
  ON: { name: 'Ontario' },
  PE: { name: 'Prince Edward Island' },
  QC: { name: 'Quebec' },
  SK: { name: 'Saskatchewan' },
  YT: { name: 'Yukon' },
};

const US_STATES = {
  AL: { name: 'Alabama' },
  AK: { name: 'Alaska' },
  AZ: { name: 'Arizona' },
  AR: { name: 'Arkansas' },
  CA: { name: 'California' },
  CO: { name: 'Colorado' },
  CT: { name: 'Connecticut' },
  DC: { name: 'District of Columbia' },
  DE: { name: 'Delaware' },
  FL: { name: 'Florida' },
  GA: { name: 'Georgia' },
  HI: { name: 'Hawaii' },
  ID: { name: 'Idaho' },
  IL: { name: 'Illinois' },
  IN: { name: 'Indiana' },
  IA: { name: 'Iowa' },
  KS: { name: 'Kansas' },
  KY: { name: 'Kentucky' },
  LA: { name: 'Louisiana' },
  ME: { name: 'Maine' },
  MD: { name: 'Maryland' },
  MA: { name: 'Massachusetts' },
  MI: { name: 'Michigan' },
  MN: { name: 'Minnesota' },
  MS: { name: 'Mississippi' },
  MO: { name: 'Missouri' },
  MT: { name: 'Montana' },
  NE: { name: 'Nebraska' },
  NV: { name: 'Nevada' },
  NH: { name: 'New Hampshire' },
  NJ: { name: 'New Jersey' },
  NM: { name: 'New Mexico' },
  NY: { name: 'New York' },
  NC: { name: 'North Carolina' },
  ND: { name: 'North Dakota' },
  OH: { name: 'Ohio' },
  OK: { name: 'Oklahoma' },
  OR: { name: 'Oregon' },
  PA: { name: 'Pennsylvania' },
  RI: { name: 'Rhode Island' },
  SC: { name: 'South Carolina' },
  SD: { name: 'South Dakota' },
  TN: { name: 'Tennessee' },
  TX: { name: 'Texas' },
  UT: { name: 'Utah' },
  VT: { name: 'Vermont' },
  VA: { name: 'Virginia' },
  WA: { name: 'Washington' },
  WV: { name: 'West Virginia' },
  WI: { name: 'Wisconsin' },
  WY: { name: 'Wyoming' },
  // Territories
  AS: { name: 'American Samoa' },
  GU: { name: 'Guam' },
  MP: { name: 'Northern Mariana Islands' },
  PR: { name: 'Puerto Rico' },
  VI: { name: 'U.S. Virgin Islands' },
};

export const COUNTRIES = {
  CA: {
    name: 'Canada',
    abbrv: 'Can',
    nationality: 'Canadian',
    regionLabel: 'Province',
    postalCodeLabel: 'Postal Code',
    flag: '🇨🇦',
    currency: 'CAD',
    sales_tax: 5,
    regions: CA_PROVINCES,
  },
  US: {
    name: 'United States',
    abbrv: 'USA',
    nationality: 'American',
    regionLabel: 'State',
    postalCodeLabel: 'Zipcode',
    flag: '🇺🇸',
    currency: 'USD',
    sales_tax: 0,
    regions: US_STATES,
  },
};

export const DEFAULT_CURRENCY = 'USD';

// function countryToFlag(isoCode) {
//   return typeof String.fromCodePoint !== 'undefined'
//     ? isoCode
//         .toUpperCase()
//         .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
//     : isoCode;
// }
