import types from '../constants/AppStateActionTypes';

const initialState = {
  // site_wide_banner: { key: 'st_patty', isOpen: true },
};

export default function appState(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_APPSTATE_LOGIN_FORM:
      return { ...state, toggle_login_form: action.toggle && { page: 'signup', hidden: false, ...action.toggle } };
    case types.TOGGLE_APPSTATE_SEARCH:
      return { ...state, toggle_search: action.toggle };
    case types.TOGGLE_APPSTATE_CONTACT_US_FORM:
      return { ...state, toggle_contact_us_form: action.toggle };
    case types.TOGGLE_APPSTATE_PRIVACY_POLICY:
      return { ...state, toggle_privacy_policy: action.toggle };
    case types.TOGGLE_APPSTATE_TERMS_AND_CONDITIONS:
      return { ...state, toggle_terms_and_conditions: action.toggle };
    case types.TOGGLE_APPSTATE_REAUTHENTICATION_FORM:
      return { ...state, show_reauthentication_form: action.toggle };
    case types.TOGGLE_APPSTATE_CHECKOUT_FORM:
      return { ...state, toggle_checkout_dialog: action.toggle };
    case types.SET_APP_BAR_COLOR_OVERRIDE:
      return { ...state, app_bar_color_override: action.color };
    case types.TOGGLE_SITE_WIDE_BANNER:
      return { ...state, site_wide_banner: { ...state.site_wide_banner, isOpen: action.toggle, ...action.payload } };
    case types.CLEAR_APPSTATE_KEY: {
      const newState = { ...state };
      delete newState[action.key];
      return newState;
    }
    default:
      return state;
  }
}
