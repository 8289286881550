import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router';

import {
  aboutPath,
  blogPath,
  careersPath,
  coursesPath,
  mcatFreeResourcesPath,
  mcatLandingEventsPath,
  mcatLandingPath,
  medSchoolCalculatorPath,
  pricingPath,
  scholarshipsPath,
} from 'my-core/routes';

import SocialMediaButtons from 'my-components/SocialMediaButtons';
import { Container } from 'my-layout';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    root: {
      padding: spacing(9, 0, 5),
      backgroundColor: palette.background.dark,
    },

    linksContainer: {
      color: palette.common.white,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 165px)',
      justifyContent: 'space-between',
      rowGap: spacing(6),
      columnGap: spacing(3),
      marginBottom: spacing(6),
      [breakpoints.down('sm')]: {
        rowGap: spacing(3),
        columnGap: spacing(1),
      },
    },

    column: {
      '& > *': {
        display: 'block',
        marginBottom: spacing(1),
      },
    },

    header: { marginBottom: spacing(3) },

    info: {
      color: palette.common.white,
      marginTop: spacing(2),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    support: {
      marginTop: spacing(2),
      width: '100%',
      display: 'flex',
      verticalAlign: 'middle',
      alignItems: 'center',
      justifyContent: 'center',
      color: palette.common.white,
      '& > *': { padding: spacing(0, 2) },
    },

    social: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    socialButton: {
      color: palette.primary.main,
      backgroundColor: palette.common.white,

      '&:hover': {
        color: palette.primary.dark,
        backgroundColor: palette.common.white,
      },
    },
  }),
  { name: 'Footer' },
);

export default function Footer(props) {
  const { onShowPrivacyPolicy, onShowTermsAndConditions } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container className={classes.linksContainer}>
        {[
          {
            header: 'Company',
            items: [
              { children: 'About', to: aboutPath() },
              { children: 'Careers', to: careersPath() },
              { children: 'Blog', to: blogPath() },
              { children: 'Free Resources', to: `${blogPath()}?category=free_resources` },
              { children: 'Pricing', to: pricingPath() },
              { children: 'Help Center', href: 'https://help.wizeprep.com' },
              { children: 'Scholarships', to: scholarshipsPath() },
            ],
          },
          {
            header: 'University',
            items: [
              { children: 'Calculus', to: coursesPath({ school_type: 'undergrad', subject: 'mathematics' }) },
              { children: 'Chemistry', to: coursesPath({ school_type: 'undergrad', subject: 'chemistry' }) },
              { children: 'Biology', to: coursesPath({ school_type: 'undergrad', subject: 'biology' }) },
              { children: 'Statistics', to: coursesPath({ school_type: 'undergrad', subject: 'statistics' }) },
              { children: 'Textbooks', to: '/textbooks/undergrad' },
              { children: 'See All', to: coursesPath({ school_type: 'undergrad' }), variant: 'subtitle1' },
            ],
          },
          {
            header: 'High School',
            items: [
              {
                children: (
                  <>
                    <small>HS</small> Math
                  </>
                ),
                to: coursesPath({ school_type: 'high_school', subject: 'mathematics' }),
              },
              {
                children: (
                  <>
                    <small>HS</small> Biology
                  </>
                ),
                to: coursesPath({ school_type: 'high_school', subject: 'biology' }),
              },
              {
                children: (
                  <>
                    <small>HS</small> Chemistry
                  </>
                ),
                to: coursesPath({ school_type: 'high_school', subject: 'chemistry' }),
              },
              {
                children: (
                  <>
                    <small>HS</small> English
                  </>
                ),
                to: coursesPath({ school_type: 'high_school', subject: 'english' }),
              },
              {
                children: (
                  <>
                    <small>HS</small> Textbooks
                  </>
                ),
                to: '/textbooks/high-school',
              },
              {
                children: (
                  <>
                    <small>AP</small> Textbooks
                  </>
                ),
                to: '/textbooks/ap',
              },
              { children: 'See All', to: coursesPath({ school_type: 'high_school' }), variant: 'subtitle1' },
            ],
          },
          {
            header: 'MCAT',
            items: [
              { children: 'MCAT Programs', to: mcatLandingPath() },
              { children: 'Free MCAT Resources', to: mcatFreeResourcesPath() },
              { children: 'MCAT Events', to: mcatLandingEventsPath() },
              { children: 'Med School Calculator', to: medSchoolCalculatorPath() },
              { children: 'MCAT Blog', to: `${blogPath()}?category=mcat` },
            ],
          },
        ].map(({ header, items }) => (
          <div key={header} className={classes.column}>
            <Typography className={classes.header} color="inherit" variant="subtitle1">
              {header}
            </Typography>
            {items.map(renderLink)}
          </div>
        ))}
      </Container>
      <Container>
        <div className={classes.social}>
          <SocialMediaButtons ButtonProps={{ className: classes.socialButton }} />
        </div>
        <div className={classes.support}>
          {renderLink({ children: 'Terms & Conditions', onClick: onShowTermsAndConditions, component: 'button' })}|
          {renderLink({ children: 'Privacy Policy', onClick: onShowPrivacyPolicy, component: 'button' })}
        </div>
        <div className={classes.info}>
          <Typography variant="subtitle1">Wizedemy Inc. ©{new Date().getFullYear()}</Typography>
        </div>
      </Container>
    </div>
  );

  function renderLink(props, key) {
    return (
      <Link
        key={key}
        color="inherit"
        component={props.href ? 'a' : RouterLink}
        underline="hover"
        {...props}
        {...(props.href && { target: '_blank', rel: 'noreferrer' })}
      />
    );
  }
}

Footer.propTypes = {
  onShowPrivacyPolicy: PropTypes.func.isRequired,
  onShowTermsAndConditions: PropTypes.func.isRequired,
};
