import loadable from '@loadable/component';

import LoadingPage from 'my-components/LoadingPage';

export default function (loader, { fallback, hideLoader } = {}) {
  // return loadable(loader, { fallback: hideLoader ? null : <Loading {...LoadingProps} /> });
  return loadable(retryLoader(loader), {
    fallback: fallback || (hideLoader ? null : <LoadingPage />),
  });
}

function retryLoader(lazyComponent) {
  return () => retryLoaderPromise(lazyComponent, 3);
}
function retryLoaderPromise(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        if (attemptsLeft === 1) {
          reject(error);
          return;
        }
        // let us retry after 1500 ms
        setTimeout(() => {
          retryLoaderPromise(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}
