import * as Sentry from '@sentry/browser';
import memoize from 'memoize-one';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onlineCourseBookletBannerLinkClicked } from 'my-core/gtm-events';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleSiteWideBanner } from 'my-actions/AppStateActions';

const classes = {
  root: ({ palette, spacing }) => ({
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(0, 2),

    backgroundColor: palette.yellow.background,
    color: palette.grey[900],
  }),
  root_loaded: ({ palette }) => ({
    backgroundColor: palette.green.background,
  }),
  closeButton: ({ palette, spacing }) => ({
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    width: 32,
    height: 32,
    color: palette.grey[900],
  }),
};
export const BOOKLET_BANNER_KEY = 'booklet-print';
export default function SiteWideBannerPrintBooklet({ link, onClose }) {
  const initializedRef = useRef(false);
  const dispatch = useDispatch();

  const notification = useSelector(state => getMostRecentPrintBookletNotification(state.notifications.items));

  useEffect(() => {
    if (link) return;
    const t = setTimeout(() => {
      Sentry.captureMessage('Online Course Booklet Print > 90s');
    }, 90 * 1000);
    return () => clearTimeout(t);
  }, [link]);

  // This effect watches for changes in the print booklet notifications and
  // updates the banner when the last (print booklet) notification changes.
  // Usually this means the banner will go from "loading" -> "ready" state but
  // it could also go directly from "ready" -> "ready" state if the banner is in
  // the "ready" state and another booklet is requested that has already been
  // generated and cached. There is a little quirk with this code wherein if the
  // most recent print booklet notification is deleted while the banner is
  // mounted, the banner will be updated with data the last notification that
  // still exists. We could fix this by comparing the notifications between
  // renders but I don't think it is worth the effort to resolve.
  useEffect(() => {
    // Skip the initial render because there may be a pre-existing print booklet
    // notification that we don't want to incidentally update the banner with
    // its data
    if (!initializedRef.current) {
      initializedRef.current = true;
      return;
    }

    if (!notification) return;

    dispatch(toggleSiteWideBanner(true, { key: BOOKLET_BANNER_KEY, props: { link: notification.data.download_path } }));
  }, [dispatch, notification]);

  if (link) {
    return (
      <>
        <div css={[classes.root, classes.root_loaded]}>
          <Typography component={FontAwesomeIcon} icon={faFileCheck} sx={{ color: 'green.medium' }} />
          <Typography sx={{ ml: 2, mr: 3 }} variant="subtitle1">
            Booklet ready!
          </Typography>
          <Button
            color="primary"
            component="a"
            href={link}
            onClick={() => {
              onClose();
              onlineCourseBookletBannerLinkClicked();
            }}
            rel="noreferrer"
            target="_blank"
            variant="contained"
          >
            Open Booklet
          </Button>
        </div>
        <IconButton css={classes.closeButton} onClick={onClose} size="small">
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </>
    );
  }

  return (
    <div css={classes.root}>
      <CircularProgress size={18} sx={{ color: 'yellow.dark' }} />
      <Typography sx={{ ml: 2 }} variant="body1">
        <Typography component="span" sx={{ mr: 1 }} variant="subtitle1">
          Generating booklet
        </Typography>
        This may take a moment
      </Typography>
    </div>
  );
}

const getMostRecentPrintBookletNotification = memoize(notifications =>
  Object.values(notifications).findLast(n => n.type === 'OnlineCoursePdfReady' && n.data.download_path),
);
