import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router';

import { appBarNavLinkClicked } from 'my-core/gtm-events';
import { TRACKING_DISABLED } from 'my-core/tag-manager';

import DropDownButton from 'my-elements/DropDownButton';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {},
    root_active: {},
    root_open: {},

    listItem: {
      color: palette.text.primary,

      '&:hover, &$listItem_active': { backgroundColor: palette.action.hover },
    },
    listItem_active: {},
    listItemIcon: { minWidth: 40 },
  }),
  { name: 'AppBarNavLink' },
);

export default function AppBarNavLink(props) {
  const { label, location, path, paths } = props;
  const classes = useStyles(props);

  const [buttonOpen, setButtonOpen] = useState(false);

  function matchPath(path) {
    if (!path) return false;
    const [pathname, search] = typeof path === 'string' ? path.split('?') : [path.pathname, path.search?.substring(1)];
    return (location.pathname === pathname && !search) || location.search.includes(search);
  }

  if (paths) {
    return (
      <DropDownButton
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        button={
          <Button
            className={classNames(classes.root, {
              [classes.root_active]: paths.some(p => matchPath(p.path)),
              [classes.root_open]: buttonOpen,
            })}
            disableRipple
            endIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}
            onClick={() => appBarNavLinkClicked(label)}
          >
            {label}
          </Button>
        }
        disablePortal
        keepMounted={TRACKING_DISABLED}
        onToggleOpen={setButtonOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List component="nav">
          {paths.map(({ icon, label, path }, i) => (
            <ListItem
              key={i}
              className={classNames(classes.listItem, { [classes.listItem_active]: matchPath(path) })}
              component={Link}
              onClick={() => appBarNavLinkClicked(label)}
              to={path}
            >
              {icon && (
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon fixedWidth icon={icon} size="lg" />
                </ListItemIcon>
              )}
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </DropDownButton>
    );
  }

  return (
    <Link
      className={classNames(classes.root, { [classes.root_active]: matchPath(path) })}
      onClick={() => appBarNavLinkClicked(label)}
      to={path}
    >
      {label}
    </Link>
  );
}
