import { StyledEngineProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import ReactOnRails from 'react-on-rails/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';

import { trackCoreWebVitals } from 'my-core/gtm-events';

import MainStyles from 'my-components/Root/MainStyles';
import TagManager from 'my-components/TagManager';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function MainApp() {
  const mainStore = ReactOnRails.getStore('mainStore');
  useEffect(() => {
    trackCoreWebVitals();
    window.dispatchAppMountedEvent?.();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={mainStore}>
          <BrowserRouter>
            <MainStyles />
            <TagManager />
          </BrowserRouter>
        </Provider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}
